module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/codebuild/output/src997427810/src/ek-kantine.nl/node_modules/gatsby-theme-psg/src/cms.js","manualInit":true,"enableIdentityWidget":false,"publicPath":"admin","htmlTitle":"Content Manager"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T5JCXW4","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EK Kantine","short_name":"EK Kantine","start_url":"/","icon":"/codebuild/output/src997427810/src/ek-kantine.nl/src/img/icon.png","background_color":"#ffffff","theme_color":"#ff7800","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
